@charset "UTF-8";

.urlError {
    opacity: 0.9;
    position: absolute;
    z-index: 1;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;
    font-size: large;
    text-align: justify;
    display: block;
    color: white;
    width: 100%;
    min-width: 240px;
    margin: auto;
    margin-top: -10px;
}

.urlError .urlErrorValid {
    margin: auto;
    text-align: center;
    padding: 1em;
    color: red;
    background-color: rgba(255, 255, 255,1);
}

.urlError .urlErrorExample {
    display: block;
    text-align: center;
    padding: 1em;
}

.spinner {

    position: absolute;
    left: 45%;
    top: 5em;

}

.spinner-page-info-small{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-info {
    position: relative;
    display: block;
    padding: 0em;
    background-color: rgba(211, 211, 211, 0.3);
    width: 100%;
    max-height: 100%;
    overflow: auto;
}

.main-container-pageinfo {
    display: block;
    box-sizing: border-box;
    padding: 0em 0em 0em 0em;
    width: 100%;
    background-color: rgb(18, 12, 36);
    background-repeat: no-repeat;
    background-size: cover;
}

.main-content-pageinfo {
    box-sizing: border-box;
    margin: auto;
    width: 100%;
    display: block;
    background-color: rgb(0, 103, 106);
    padding: 0em;
}

.main-content-pageinfo table{
    margin: 0;
}

.page-info-table-small{
    display: none;
}

.question-container {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 100%;
    background-color: rgb(165, 154, 154);
    color: rgb(71, 69, 69);
    margin-left: 1%;
    text-align: center;
}

.tooltiptext {
    display: none;
}

th.snapshot-version-header {
    position: relative;
}

.question-container:hover .tooltiptext {
    z-index: 1;
    position: absolute;
    display: block;
    background-color: rgba(238, 245, 242, 0.99);
    border-radius: 5%;
    color: rgb(4, 170, 109, 1.0);
    width: 15em;
    height: 7em;
    padding: 1em;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    top: 0em;
    left: 15em;
}

iframe {
    margin-top: 0%;
    width: 100%;
    height: 1000px;
}

/* Style for the navbar */

.navbar {
    z-index: 2;
    display: block;
    overflow: hidden;
    background-color: rgb(46, 31, 94);
}

.navbar .right-nav-link {
    box-sizing: border-box;
    float: right;
    color: #f2f2f2;
    text-align: center;
    padding: 1em;
    margin-top: 0.5em;
    margin-right: 0.5em;
    text-decoration: none;
    font-size: 1.07em;
    font-family: Arial, Helvetica, sans-serif;
}

.navbar .right-nav-link:hover {
    color: rgb(160, 154, 154);
}

.navbar .link-selected {
    background-color: white;
    color: rgb(46, 31, 94);
}

.navbar .link-selected:hover {
    background-color: white;
    color: rgb(46, 31, 94);
}

.navbar .link-selected:hover .navbar .right-nav-link:hover {
    background-color: white;
    color: rgb(46, 31, 94);
}

.navbar .img {
    float: left;
    margin: 0.2em 1em 0.2em 0.7em;
    margin-bottom: 10px;
    height: 3.5em;
    width: 3.5em;
}

.navbar .img:hover {
    opacity: 0.8;
}

.navbar .logo-text {
    color: white;
    box-sizing: border-box;
    float: left;
    font-size: 1.24em;
    padding: 0;
    margin-top: 1.3em;
    margin-left: 0.3em;
    font-family: Arial, Helvetica, sans-serif;
}

.navbar .logo-text:hover {
    opacity: 0.8;
}

.dropdown {
    display: none;
}

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-width: 274px;
}

/* Style for the header */
header {
    background-color: transparent;
    color: #fff;
    text-align: center;
    padding: 12px;

}

h2 {
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 12px;
}

/* Style for the form */

.main-container {
    display: inline-block;
    box-sizing: border-box;
    padding: 1em;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(25, 17, 49);
    min-height: 85vh;
    background-image: url("../../archive_room2.jpg");
}

.main-content {
    box-sizing: border-box;
    margin: auto;
    max-width: 50em;
    display: block;
    padding: 1em;
    min-height: 80vh;
    margin-top: 13%;
}

.form {
    position: relative;
}

form .search-bar {
    position: relative;
    display: flex;
    height: 3.1em;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 0.6%;
}

form .search-bar input[type="text"] {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-style: italic;
    font-weight: 900;
    color: rgb(71, 68, 68);
    width: 100%;
    max-width: 100%;
    padding-left: 7em;
    padding-right: 1%;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    border: none;
    padding-right: 3em !important;
}

textarea:focus,
input:focus {
    outline: none;
}

form .search-bar a {
    position: relative;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    width: 100%;
    max-width: 15%;
    background-color: white;
    border-radius: 0 10px 10px 0;
}

form .search-bar  #icon {  /* .icon-container */
    position: absolute;
    right: 2%;
    opacity: 1;
    top: 23%;
    height: fit-content;
    width: fit-content;
    font-size: 1.5em;
    color: rgb(160, 160, 160);
}

form .search-bar #icon:hover {  /* .icon-container:hover */
    color: rgb(62, 163, 138);
}

form .search-bar .selection-container {
    box-sizing: border-box;
    z-index: 2;
    position: absolute;
    height: 100%;
    padding: 0.15em 0.25em 0px 0.25em;
    margin: 0;
    margin-top: 0px;
    border-radius: 10px 10px 10px 10px;
}

form .search-bar .selection-container .select {
    box-sizing: border-box;
    z-index: 2;
    display: inline-block;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    margin-top: 2%;
    margin-left: auto;
    font-size: 1em;
    height: fit-content !important;
    background-color: rgb(211, 211, 211);
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    border: 1px solid gray;
}

.select:focus {
    outline: none;
    caret-color: #198754;

}

.button-container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    width: 100px;
    height: 50px;
    background-color: white;
    color: #198754;
    border-radius: 5px;
    margin: auto;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
}

button:enabled:hover {
    background-color: #198754;
    color: white;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info-button {
    margin: 0;
    width: 100%;
}

.page-info-button button {
    margin: 0;
}

.page-info-button a {
    margin: 0 1em;
}

/* Style for the footer */

.second-buttons {
    align-items: center;
    max-width: 80%;
    color: navy;
}

.second-buttons:enabled:hover {
    background-color: navy;
    color: white;
}

.paragraph {
    position: relative;
    max-width: 100%;
}

.paragraph1 {
    position: relative;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 3%;
    color: white;
}

.archived-page {
    position: relative;
    margin-top: 1em;
    display: block;

    background-color: rgba(211, 211, 211, 0.3);
    height: 50vh;
    max-height:50vh;
    overflow: auto;
}

.table-container td {
    text-align: center;
    

}

.table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-collapse: collapse;
    padding: 1em;
    overflow: auto;
}

table,
.page-info-table {
    width: 100%;
    margin-top: 2.5%;
    border-collapse: collapse;
}

.table-header {
    box-sizing: border-box;
    background-color:rgb(0, 103, 106);
    background-image: linear-gradient(rgb(18, 12, 36,0), rgba(7, 4, 14, 0.3));
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    border-collapse: collapse;
    text-align: center;
    padding: 16px;
    width: 100%;
}

th,
td {
    text-align: center;
    padding: 0.5em;
    color: white;
    background-color: #6b6b6b;
    border-bottom: 1px solid gray;
    border: 1px solid rgba(136, 131, 131, 0.9);
}

tr td a {
    color: white;
}

th {
    text-align: left;
    color: white;
    background-color:rgb(0, 103, 106);
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.permanent-link-td {
    color: white;
    background-color: #6b6b6b;
    text-align: left;
}

.check {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Centers vertically */
    justify-content: center;
    /* Centers horizontally */
    background-color: #04aa6d;
    border-radius: 50%;
    width: 2em;
    height: 2em;
}

.xmark {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Centers vertically */
    justify-content: center;
    /* Centers horizontally */
    background-color: #04aa6d;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    background-color: red;
    color: rgb(236, 232, 232);
}

footer a {
    color: white;
    word-break: break-all;
    text-decoration: none;
}

a {
    word-break: break-all;
    text-decoration: none;
}

.not-archived {
    text-align: center;
    color: #fff;
    margin-bottom: 5%;
}

.mailto{
    color: white;
    text-decoration: underline;
}

/* ABOUT PAGE*/

.about {
    background-color: #141414;
}

.main-container-about {
    display: inline-block;
    box-sizing: border-box;
    padding: 1em;
    padding-top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-body {
    box-sizing: border-box;
    display: block;
    width: 90%;
    margin: auto;
    min-height: 80vh;
    background-color: #242424;
    padding: 1em;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.about-body h1 {
    text-align: left;
    border-bottom: 0.5px dotted rgb(235, 229, 229);
}

.about-body h2 {
    text-align: left;
    border-bottom: 0.5px dotted rgb(235, 229, 229);
}

.about-body p {
    text-align: left;
}

.about-body::after {
    content: "";
    clear: both;
    display: table;
}

footer {
    color: black;
    padding: 1em;
    display: flex;
    /* Use Flexbox to align items horizontally */
    flex-direction: row;
    justify-content: center;
    /* Center the items horizontally */
    align-items: center;
    font-weight: normal;
}

.main-footer a {
    color: black;
}

.contact-body-footer{
    margin-top: 2em;
    background-color: white;
    background-color: #141414;
}

.about-body-footer{
    margin-top: 2em;
    background-color: white;
    background-color: #141414;
}

/* Mobile styles */

@media (max-width: 992px) {
    .main-container {
        min-height: 80vh;
    }

    .main-content {
        top: 23%;
        left: 5%;
        width: 90%;
    }

    .main-content {
        top: 25%;
        left: 10%;
        width: 80%;
    }

    .page-info-table {
        display: none;
    }

    .page-info-table-small{
        display: block;
    }

    .question-container:hover .tooltiptext {
        z-index: 1;
        position: absolute;
        display: block;
        background-color: rgba(238, 245, 242, 0.99);
        border-radius: 5%;
        color: rgb(4, 170, 109, 1.0);
        ;
        width: 15em;
        height: 7em;
        padding: 1em;
        text-align: left;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.9em;
        top: 0em;
        left: 3em;
    }

    .spinner .color-ring {
        background-color: black;
        position: relative;
        top: 10em;
    }

    form .search-bar input[type="text"] {
        padding-left: 6.7em;
        padding-right: 3.8em;
    }

}

@media (max-width: 768px) {

    .main-container {
        min-height: 80vh;
    }

    .main-content {
        top: 20%;
        left: 5%;
        width: 90%;
    }

    form .search-bar input[type="text"] {
        padding-left: 6em;
        padding-right: 3.5em;
    }

    form .search-bar .selection-container .select {
        font-size: 1em;
        height: 2.6em;
        width: 85px;
        align-items: center;
    }

    .nav.right-nav-link {
        float: right;
        color: #f2f2f2;
        text-align: center;
        margin-right: 0.1em;
        text-decoration: none;
        font-size: 17px;
        font-family: Arial, Helvetica, sans-serif;
    }

    .logo-text {
        display: none;
    }

}

@media screen and (max-width: 576px) {

    .main-container {
        padding: 0em;
    }

    .main-content {
        width: 100%;
        margin: 0;
    }

    .dropbtn {
        color: white;
        font-size: 150%;
        text-align: center;
        padding: 16px 16px;
        text-decoration: none;
    }

    .dropbtn:hover {
        background-color: #ddd;
        color: black;
    }

    .dropdown {
        display: block;
        box-sizing: border-box;
        float: right;
        margin-right: 5%;
        margin-top: 0.5em;
        height: 7vh;
    }

    .dropdown-content-none {
        float: none;
        display: none;
        position: absolute;
        right: 0;
        margin-right: 5%;
        margin-left: 5%;
        padding-right: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content {
        width: 100%;
        float: none;
        display: none;
        position: absolute;
        right: 0;
        margin-right: 0%;
        margin-left: 0%;
        background-color: rgb(70, 55, 119);
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 3;
    }

    .dropdown-content a {
        color: #f2f2f2;
        padding: 24px 0px;
        width: 100%;
        text-decoration: none;
        display: block;
        text-align: center;
        border-bottom: 1px solid rgb(202, 197, 197);

    }

    .dropdown-content a:hover {
        background-color: #f2f2f2;
        color: black;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropbtn {

        display: inline-block;

    }

    .dropdown-content {
        display: block;

    }

    .dropdown-content a:hover body {
        opacity: 0.9;

    }

    form .search-bar input[type="text"] {
        padding-left: 5.5em;
        padding-right: 3.5em;
    }

    form .search-bar .selection-container .select {
        font-size: 0.7em;
        height: 3.5em;
        width: 70px;
        align-items: center;
        margin-top: 3px;
    }


    .navbar .right-nav-link {
        display: none;
    }


    tr:nth-child(even) {
        width: 100%;
        background-color: #f2f2f2;
    }

    .page-info-button {
        box-sizing: border-box;
        margin: 0;
        width: 90vw;
        padding-left: 1%;
    }

    .page-info-button button {
        width: 32%;
        margin-right: 0;
        margin-left: 0%;
        margin: 0;
    }

    .page-info-button a {
        margin: 0 1%;
    }
}

@media screen and (max-width: 320px) {

    .second-part .bottom-links {
        visibility: hidden;
    }

}